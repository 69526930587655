import dayjs from "dayjs";

import { convertToISODate } from "./time";

/**
 * Calls /api/query endpoint to run an analysis.
 * This serves as a wrapper to convert the result x and label fields, to dayjs dates, if a DATE label type is returned.
 *
 * @param client API client
 * @param analysisId the analysis ID
 * @param analysisParameters the analysis parameters
 * @param filtersInParameters the dimensions filter in parameters
 * @param filtersOutParameters the dimensions filter out parameters.
 * @param analysisCustomParameters the custom parameters.
 */
export function runAnalysis(client, analysisId, analysisParameters, filtersInParameters, filtersOutParameters, analysisCustomParameters) {
    const newAnalysisParameters = Object.fromEntries(
        Object.entries(analysisParameters).map(([key, value]) => {
            let newValue = value;

            // since dates are a URL parameter, inside a JSON object, it will not be deserialized as dayjs object.
            // so, we need to enforce dayjs here.
            if (key === "DATE") {
                const [startDate, endDate] = value;
                newValue = [convertToISODate(dayjs.utc(startDate)), convertToISODate(dayjs.utc(endDate))];
            } else if (key === "FOREX_DATE" || key === "CUTOFF_DATE") {
                newValue = convertToISODate(dayjs.utc(value));
            }

            return [key, newValue];
        }));

    return client.query.queryRun(analysisId,
        {
            parameters: newAnalysisParameters,
            filters_in_parameters: filtersInParameters,
            filters_out_parameters: filtersOutParameters,
            custom_parameters: analysisCustomParameters,
        }).then((results) => {
        if (results.label_type.x !== "DATE") {
            return results;
        }

        const parsedResult = results.result.map(entry => ({
            ...entry,

            // the internal representation shows dates as local timezone.
            // However, all methods operate and return as UTC.
            x: dayjs.utc(entry.x),
            label: dayjs.utc(entry.label),
        }));

        return { ...results, result: parsedResult };
    });
}

/**
 * Calls /api/analysis/{analysis_id} endpoint to get an analysis.
 * This serves as a wrapper to convert the customization parameter DATE as dayjs dates.
 *
 * @param client API client
 * @param analysisId the analysis ID
 */
export function getAnalysis(client, analysisId) {
    return client.analysis.analysisGet(analysisId)
        .then((analysis) => {
            const customization_parameters = analysis.customization_parameters.map((parameter) => {
                if (parameter.type === "DATE") {
                    return {
                        ...parameter,
                        default: [dayjs.utc(parameter.default[0]), dayjs.utc(parameter.default[1])],
                    };
                } else {
                    return parameter;
                }
            });

            return { ...analysis, customization_parameters: customization_parameters };
        });
}

/**
 * Calls /api/report/{report_id} endpoint to get a report.
 * This serves as a wrapper to convert the report dates as dayjs dates.
 *
 * @param client API client
 * @param reportId the report ID
 */
export function getReport(client, reportId) {
    return client.report.reportGetReport(reportId)
        .then((report) => {
            const newReport = {
                ...report,
                start_date: dayjs.utc(report.start_date),
                end_date: dayjs.utc(report.end_date),
            };

            if (report.cutoff_date) {
                newReport.cutoff_date = dayjs.utc(report.cutoff_date);
            }

            return newReport;
        });
}

/**
 * Calls /api/report endpoint to create a report.
 * This serves as a wrapper to convert the report dates as dayjs dates.
 */
export function createReport(client, name, templateId, startDate, endDate, scenarioId, cutoffDate, multiplier, hideEmptyRows,
    hideInitialMonths, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) {
    return client.report.reportCreateReport(
        {
            name: name,
            template: templateId,
            scenario: scenarioId, // scenario is optional
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: cutoffDate ? convertToISODate(cutoffDate) : null,
            multiplier: multiplier,
            hide_empty_rows: hideEmptyRows,
            hide_initial_months: hideInitialMonths,
            ui_filters: {
                dimensions_filters_in: filtersInDimensions,
                parameters_filters_in: filtersInParameters,
                dimensions_filters_out: filtersOutDimensions,
                parameters_filters_out: filtersOutParameters,
            },
        });
}

/**
 * Calls /api/report/{report_id}/update endpoint to update a report.
 * This serves as a wrapper to convert the report dates as dayjs dates.
 */
export function updateReport(client, reportId, name, templateId, startDate, endDate, scenarioId, cutoffDate, multiplier, hideEmptyRows,
    hideInitialMonths, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) {
    return client.report.reportUpdateReport(reportId,
        {
            name: name,
            template: templateId,
            scenario: scenarioId, // scenario is optional
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: cutoffDate ? convertToISODate(cutoffDate) : null,
            multiplier: multiplier,
            hide_empty_rows: hideEmptyRows,
            hide_initial_months: hideInitialMonths,
            ui_filters: {
                dimensions_filters_in: filtersInDimensions,
                parameters_filters_in: filtersInParameters,
                dimensions_filters_out: filtersOutDimensions,
                parameters_filters_out: filtersOutParameters,
            },
        });
}

/**
 * Calls /api/report/{report_id}/clone endpoint to clone a report.
 * This serves as a wrapper to convert the report dates as dayjs dates.
 */
export function cloneReport(client, reportId, name, templateId, startDate, endDate, scenarioId, cutoffDate, multiplier, hideEmptyRows,
    hideInitialMonths, filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) {
    return client.report.reportCloneReport(reportId,
        {
            name: name,
            template: templateId,
            scenario: scenarioId, // scenario is optional
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: cutoffDate ? convertToISODate(cutoffDate) : null,
            multiplier: multiplier,
            hide_empty_rows: hideEmptyRows,
            hide_initial_months: hideInitialMonths,
            ui_filters: {
                dimensions_filters_in: filtersInDimensions,
                parameters_filters_in: filtersInParameters,
                dimensions_filters_out: filtersOutDimensions,
                parameters_filters_out: filtersOutParameters,
            },
        });
}

/**
 * Calls /api/report/{report_id}/compute endpoint to compute a report.
 * This serves as a wrapper to convert the report result dates as dayjs dates.
 *
 * @param client API client
 * @param reportId the report ID
 * @param startDate start date
 * @param endDate end date
 * @param scenarioId scenario ID if chosen
 * @param cutoffDate cutoffDate if chosen
 * @param filtersInDimensions filters in
 * @param filtersInParameters filters in
 * @param filtersOutDimensions filters out
 * @param filtersOutParameters filters out
 */
export function computeReport(client, reportId, startDate, endDate, scenarioId, cutoffDate,
    filtersInDimensions, filtersInParameters, filtersOutDimensions, filtersOutParameters) {
    return client.report.reportComputeReport(reportId, {
        scenario_id: scenarioId,
        start_date: convertToISODate(startDate),
        end_date: convertToISODate(endDate),
        cutoff_date: cutoffDate ? convertToISODate(cutoffDate) : null,
        ui_filters: {
            dimensions_filters_in: filtersInDimensions,
            parameters_filters_in: filtersInParameters,
            dimensions_filters_out: filtersOutDimensions,
            parameters_filters_out: filtersOutParameters,
        },
    }).then((reportResults) => {
        const newResults = reportResults.results.map((entry) => {
            const newEntry = { ...entry };

            if (entry.actual) {
                newEntry.actual = {
                    ...entry.actual,
                    dates: Object.fromEntries(Object.entries(entry.actual.dates).map(([date, value]) => [dayjs.utc(date), value])),
                };
            }

            if (entry.reference) {
                newEntry.reference = {
                    ...entry.reference,
                    dates: Object.fromEntries(Object.entries(entry.reference.dates).map(([date, value]) => [dayjs.utc(date), value])),
                };
            }

            if (entry.ar_diff) {
                newEntry.ar_diff = {
                    ...entry.ar_diff,
                    dates: Object.fromEntries(Object.entries(entry.ar_diff.dates).map(([date, value]) => [dayjs.utc(date), value])),
                };
            }

            if (entry.ar_div) {
                newEntry.ar_div = {
                    ...entry.ar_div,
                    dates: Object.fromEntries(Object.entries(entry.ar_div.dates).map(([date, value]) => [dayjs.utc(date), value])),
                };
            }

            return newEntry;
        });

        return { ...reportResults, results: newResults };
    });
}

/**
 * Calls /api/config endpoint to get the config.
 * This serves as a wrapper to convert the config dates as dayjs dates.
 *
 * @param client API client
 */
export function getConfig(client) {
    return client.config.configurationGet()
        .then((config) => {
            const newConfig = { ...config };

            newConfig.etl.date = dayjs.utc(config.etl.date);

            newConfig.time.global.min = dayjs.utc(config.time.global.min);
            newConfig.time.global.max = dayjs.utc(config.time.global.max);

            newConfig.time.historical.min = dayjs.utc(config.time.historical.min);
            newConfig.time.historical.max = dayjs.utc(config.time.historical.max);

            newConfig.time.reference.min = dayjs.utc(config.time.reference.min);
            newConfig.time.reference.max = dayjs.utc(config.time.reference.max);

            if (config.time.forecasting) {
                newConfig.time.forecasting.min = dayjs.utc(config.time.forecasting.min);
                newConfig.time.forecasting.max = dayjs.utc(config.time.forecasting.max);
            }

            if (config.time.cutoff_date) {
                newConfig.time.cutoff_date = dayjs.utc(config.time.cutoff_date);
            }

            return newConfig;
        });
}

/**
 * Calls /api/collector/{collector_id} endpoint to get a collector.
 * This serves as a wrapper to convert the collector dates as dayjs dates.
 *
 * @param client API client
 * @param collectorId the collector ID
 */
export function getCollector(client, collectorId) {
    return client.collector.collectorGetCollector(collectorId)
        .then(collector => ({
            ...collector,
            start_date: dayjs.utc(collector.start_date),
            end_date: dayjs.utc(collector.end_date),
            cutoff_date: dayjs.utc(collector.cutoff_date),
        }));
}

/**
 * Calls /api/collector endpoint to create a collector.
 * This serves as a wrapper to convert the collector dates as dayjs dates.
 */
export function createCollector(client, name, template, startDate, endDate, cutoffDate) {
    return client.collector.collectorCreateCollector(
        {
            name: name,
            template: template,
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: convertToISODate(cutoffDate),
        });
}

/**
 * Calls /api/collector/{collector_id}/clone endpoint to clone a collector.
 * This serves as a wrapper to convert the collector dates as dayjs dates.
 */
export function cloneCollector(client, collectorId, name, template, startDate, endDate, cutoffDate) {
    return client.collector.collectorCloneCollector(collectorId,
        {
            name: name,
            template: template,
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            cutoff_date: convertToISODate(cutoffDate),
        });
}

/**
 * Calls /api/procurement/{project_id} endpoint to get a project.
 * This serves as a wrapper to convert the project dates as dayjs dates.
 *
 * @param client API client
 * @param projectId the project ID
 * @param includePrioritize to calculate project prioritize
 * @param includeSchedule to calculate project schedule
 */
export function getProject(client, projectId, includePrioritize = false, includeSchedule = false) {
    return client.procurement.procurementGetProject(projectId, includePrioritize, includeSchedule)
        .then(project => ({
            ...project,
            start_date: dayjs.utc(project.start_date),
            end_date: dayjs.utc(project.end_date),
            implementation_start_date: dayjs.utc(project.implementation_start_date),
        }));
}

/**
 * Calls /api/procurement endpoint to create a project.
 * This serves as a wrapper to convert the project dates as dayjs dates.
 */
export function createProject(client, name, startDate, endDate, scope) {
    return client.procurement.procurementCreateProject(
        {
            name: name,
            start_date: convertToISODate(startDate),
            end_date: convertToISODate(endDate),
            scope: scope,
        });
}

/**
 * Calls /api/procurement/{project_id}/schedule endpoint to schedule a project.
 * This serves as a wrapper to convert the project dates as dayjs dates.
 */
export function scheduleInitiatives(client, projectId, payload, implementationStartDate, dryRun = false) {
    return client.procurement.procurementScheduleInitiatives(projectId, payload, convertToISODate(implementationStartDate), dryRun)
        .then(project => ({
            ...project,
            start_date: dayjs.utc(project.start_date),
            end_date: dayjs.utc(project.end_date),
            implementation_start_date: dayjs.utc(project.implementation_start_date),
        }));
}
